import { I13nAnchor } from "@yahoo-creators/i13n";
import { type FC } from "react";
import { useIntl } from "react-intl";
import TwitterLogo from "../svg/social/twitter.svg";
import { type SocialLinksComponentType } from "./SocialLinks";

export const Twitter: FC<SocialLinksComponentType> = ({
  className,
  i13nSubsec,
  style,
  url,
}) => {
  const intl = useIntl();

  return (
    <I13nAnchor
      aria-label={intl.formatMessage({ id: "footer.TWITTER" })}
      className={className}
      dataYlk={{
        elm: "follow",
        itc: 0,
        outcm: "follow",
        slk: "twitter",
        subsec: i13nSubsec,
      }}
      href={url}
      rel="noreferrer noopener"
      style={style}
      target="_blank"
    >
      <TwitterLogo />
    </I13nAnchor>
  );
};
