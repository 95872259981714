import { type FC } from "react";
import { getDataYlkString } from "../lib/i13n";
import { type YlkData } from "../types/ylk";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  dataYlk: YlkData;
}

export const I13nAnchor: FC<Props> = ({ children, dataYlk, ...rest }) => {
  return (
    // This is the ONE place where we expect to have an anchor element
    // eslint-disable-next-line react/forbid-elements
    <a data-ylk={getDataYlkString(dataYlk)} {...rest}>
      {children}
    </a>
  );
};
