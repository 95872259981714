"use client";

import { getPerfVitalsUrl } from "@vzmi/perf-vitals";
import {
  type Config as RapidConfig,
  type Instance as RapidInstance,
} from "@vzmi/types-rapid";
import Script from "next/script";
import type {} from "../types/Window";

import { type FC, useEffect, useState } from "react";

const RAPID_READY = "rapid:ready";

type Props = {
  /** Rapid Config can be undefined initially but eventually must be passed in to initialize rapid */
  config?: RapidConfig;
  /** CDN URL for Rapid */
  rapidUrl?: string;
};

export type BeaconClick = {
  elm?: string;
  sec: string;
  subsec?: string;
  slk: string;
  _p?: number;
  options?: {
    dwell?: "start" | "stop";
    pp?: Record<string, any>;
  };
  outcome?: string;
  pos?: number;
  cpos?: number;
  mpos?: number;
  itc?: number;
};

type BeaconLinkView = {
  elm: string;
  itc?: string | number;
  sec: string;
  slk: string;
};

let rapidPromise: Promise<RapidInstance>;
function getRapidInstance(): Promise<RapidInstance> {
  if (window.rapidInstance) {
    return Promise.resolve(window.rapidInstance);
  }

  rapidPromise =
    rapidPromise ||
    new Promise((resolve) => {
      window.addEventListener(
        RAPID_READY,
        () => {
          if (window.rapidInstance) {
            resolve(window.rapidInstance);
          }
        },
        { once: true },
      );
    });

  return rapidPromise;
}

export async function beaconClick(
  { sec, slk, _p = 0, options, outcome, ...restProps }: BeaconClick,
  callback?: () => void,
) {
  const rapidInstance = await getRapidInstance();
  if (!rapidInstance) {
    return;
  }
  rapidInstance.beaconClick(
    sec,
    slk,
    _p,
    restProps,
    outcome,
    callback,
    options,
  );
}

export async function beaconPageview(keys?: Record<string, string | number>) {
  const rapidInstance = await getRapidInstance();
  if (!rapidInstance) {
    return;
  }
  rapidInstance.beaconPageview(keys);
}

export async function beaconLinkViews({ elm, itc, sec, slk }: BeaconLinkView) {
  const rapidInstance = await getRapidInstance();
  if (!rapidInstance) {
    return;
  }

  const links = [{ _links: [{ elm, itc, slk }], sec }];
  rapidInstance.beaconLinkViews(links);
}

export async function beaconEvent(
  name: string,
  pageParams?: any,
  outcomeName?: string,
) {
  const rapidInstance = await getRapidInstance();
  if (!rapidInstance) {
    return;
  }

  rapidInstance.beaconEvent(name, pageParams, outcomeName);
}

export async function reInit(config: RapidConfig) {
  const rapidInstance = await getRapidInstance();
  if (!rapidInstance) {
    return;
  }

  rapidInstance.reInit(config);
}

export async function addContentProgression(module: string) {
  const rapidInstance = await getRapidInstance();
  if (module && rapidInstance) {
    rapidInstance.addModuleProgression(module);
  }
}

export async function endContentProgression(module: string) {
  const rapidInstance = await getRapidInstance();
  if (module && rapidInstance) {
    rapidInstance.endModuleProgression(module);
  }
}

function handleRapidClicks(e: MouseEvent) {
  const target = e.target as HTMLElement;
  // only targets within the boundary will be tracked, otherwise use Rapid's auto tracking
  const i13nBoundary = target.closest("[data-i13n-boundary]");
  if (!i13nBoundary) {
    return;
  }
  const isRapidDisabled = target.classList.contains("rapid-noclick-resp");
  const dataYlk = target.getAttribute("data-ylk");

  if (isRapidDisabled && dataYlk) {
    const ylkParts = dataYlk.split(";");
    const beaconObject: any = {};

    for (const pair of ylkParts) {
      const [key, value] = pair.split(":");
      beaconObject[key] = value;
    }

    beaconClick(beaconObject);
  }
}

export const Rapid: FC<Props> = ({
  config,
  rapidUrl = "https://s.yimg.com/ss/analytics-3.57.2.js",
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!config || !isLoaded || isInitialized || !window.YAHOO?.i13n?.Rapid) {
      return;
    }

    window.rapidInstance = new window.YAHOO.i13n.Rapid(config);
    window.dispatchEvent(new CustomEvent(RAPID_READY));
    document.body.addEventListener("click", handleRapidClicks);

    setIsInitialized(true);
  }, [config, isInitialized, isLoaded]);

  return (
    <>
      <Script
        src={rapidUrl}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />
      {isInitialized ? <Script src={getPerfVitalsUrl()} /> : null}
    </>
  );
};
