"use client";
import { type DeviceSize } from "@yahoo-news/util";
import Script from "next/script";
import type {} from "../types/Window";
import { type FC, useEffect, useState } from "react";

type Props = {
  /** CDN URL for AdobeJS */
  adobejsUrl?: string;
  config: {
    device?: DeviceSize | null;
    site?: string;
    prop53?: string;
    isModal?: boolean;
  };
};

/** Fire PageView beacon on Seamless article scroll or on client side navigation */
/** prop53 value will be modal or modal close */
export async function adobePVBeacon(prop53: string) {
  if (window.s) {
    window.s.prop53 = prop53;
    window.s.t();
  }
}

export const Adobe: FC<Props> = ({
  adobejsUrl = "https://s.yimg.com/cv/apiv2/partner-portals/att/adobe_analytics/1.1.20/s_code_yahoo.js",
  config,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const adobeAnalytics = window.s;
    if (isLoaded && adobeAnalytics && !isInitialized) {
      adobeAnalytics.prop53 = config.prop53 || "";
      adobeAnalytics.pageName = `net|${config.site}|${config.device}`;
      adobeAnalytics.t();
      setIsInitialized(true);
    }
  }, [isLoaded, config, isInitialized]);

  return (
    <Script
      src={adobejsUrl}
      onLoad={() => {
        setIsLoaded(true);
      }}
      strategy="afterInteractive"
    />
  );
};
