import Link, { type LinkProps } from "next/link";
import { type FC, type ReactNode } from "react";
import { getDataYlkString } from "../lib/i13n";
import { type YlkData } from "../types/ylk";

interface Props extends LinkProps {
  children: ReactNode;
  dataYlk: YlkData;
  className?: string;
}

export const I13nLink: FC<Props> = ({ dataYlk = {}, children, ...rest }) => {
  return (
    <Link data-ylk={getDataYlkString(dataYlk)} {...rest}>
      {children}
    </Link>
  );
};
