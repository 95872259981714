import { forwardRef } from "react";
import { getDataYlkString } from "../lib/i13n";
import { type YlkData } from "../types/ylk";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dataYlk: YlkData;
}

export const I13nButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, dataYlk, ...rest }, ref) => {
    return (
      <button {...rest} data-ylk={getDataYlkString(dataYlk)} ref={ref}>
        {children}
      </button>
    );
  },
);
I13nButton.displayName = "I13nButton";
