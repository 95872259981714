import { GENDER } from "./consts";

/**
 * Generates Comscore c14 parameter data based on the user's age and gender.
 * @param age {number} User age
 * @param gender {string} User gender
 * @returns {number} Normalized Comscore c14 value
 */
export const getComscoreC14 = (age: number, gender: GENDER): number => {
  if (typeof age === "undefined") {
    return -1;
  }

  const ageInt = Number(age);

  // Male gender
  if (gender === GENDER.MALE) {
    if (isNaN(ageInt) || ageInt < 18) {
      return -1;
    }
    if (ageInt < 21) {
      return 99;
    }
    if (ageInt < 25) {
      return 100;
    }
    if (ageInt < 30) {
      return 1010;
    }
    if (ageInt < 35) {
      return 1015;
    }
    if (ageInt < 40) {
      return 1020;
    }
    if (ageInt < 45) {
      return 1025;
    }
    if (ageInt < 50) {
      return 103;
    }
    if (ageInt < 55) {
      return 104;
    }
    if (ageInt < 60) {
      return 1050;
    }
    if (ageInt < 65) {
      return 1055;
    }
    return 106;
    // Female gender
  } else if (gender === GENDER.FEMALE) {
    if (isNaN(ageInt) || ageInt < 18) {
      return -1;
    }
    if (ageInt < 21) {
      return 112;
    }
    if (ageInt < 25) {
      return 113;
    }
    if (ageInt < 30) {
      return 1140;
    }
    if (ageInt < 35) {
      return 1145;
    }
    if (ageInt < 40) {
      return 1150;
    }
    if (ageInt < 45) {
      return 1155;
    }
    if (ageInt < 50) {
      return 116;
    }
    if (ageInt < 55) {
      return 117;
    }
    if (ageInt < 60) {
      return 1180;
    }
    if (ageInt < 65) {
      return 1185;
    }
    return 119;
  }
  return -1;
};
