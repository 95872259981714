import { GENDER } from "./consts";

/**
 * Get the Comscore FPDM tag value. Based on the UDM 2.0 spec
 * @see https://drive.google.com/file/d/15LdEAihvwJz4rBVirrKSzLRGRTycvFNq/view
 */
export const getComscoreFPDM = (age: number, gender: GENDER): string => {
  age = Number(age);

  // do not track if under 18
  if (isNaN(age) || age < 18) {
    return "*null";
  }

  // evaluate gender
  let genderGroup = 0;
  switch (gender) {
    case GENDER.FEMALE:
      genderGroup = 2;
      break;
    case GENDER.MALE:
      genderGroup = 1;
      break;
    case GENDER.UNKNOWN:
      genderGroup = 0;
      break;
    case GENDER.UNSPECIFIED:
      genderGroup = 3;
      break;
  }

  // evaluate age
  const ageGroup = getAgeMapping(age);

  // demographic data calculation
  // @see https://drive.google.com/file/d/15LdEAihvwJz4rBVirrKSzLRGRTycvFNq/view
  const calc = ageGroup + genderGroup + 19991999999;

  // calculate fpdm value
  return String(calc);
};

function getAgeMapping(age: number): number {
  if (age < 21) {
    return 60;
  }
  if (age < 25) {
    return 70;
  }
  if (age < 35) {
    return 80;
  }
  if (age < 45) {
    return 90;
  }
  if (age < 55) {
    return 100;
  }
  if (age < 65) {
    return 110;
  }
  if (age > 64) {
    return 120;
  }
  return 0;
}
