const REG_EX = new RegExp("%(?!25)", "g");
import { type I13NSec } from "../index";

/**
 * Creates a ylk string from an I13n data object.
 * @param ylkData {object} I13n data object to parse
 * @returns {string} Stringified I13n data object
 */
export const getDataYlkString = (ylkData: Record<string, any>): string => {
  if (!ylkData || typeof ylkData !== "object") {
    return "";
  }

  const ylk: string[] = [];

  for (const [key, value] of Object.entries(ylkData)) {
    const type: string = typeof value;
    if (
      value !== "" &&
      (type === "string" || type === "number" || type === "boolean")
    ) {
      if (type === "string") {
        ylk.push(`${key}:${value.replace(REG_EX, "%25")};`);
      } else {
        ylk.push(`${key}:${value};`);
      }
    }
  }
  return ylk.join("");
};

/**
 * Adds a module (block level element) to Rapid, so that it will be dynamically instrumented.
 * @param id {string} Module Id/root node Id that will be tracked by Rapid
 * @param sec {string} Sec value for Rapid to map to module Id
 */
export const refreshRapid = (id: string, sec?: I13NSec) => {
  const { rapidInstance } = window;

  if (!rapidInstance) {
    return;
  }

  if (!rapidInstance.isModuleTracked(id)) {
    let modId: any = [id];
    // support optional "{ id: sec }" mapping
    if (sec) {
      modId = {
        [id]: sec,
      };
    }
    // add tracking
    // @ts-ignore need to update type to accept object
    rapidInstance.addModulesWithViewability(modId, false);
  } else {
    // refresh the module
    rapidInstance.refreshModule(id, false);
  }
};

/**
 * Creates a ylk object from an I13n ylk data string.
 * @param {string} Stringified I13n data object to parse
 * @returns ylkData {object} I13n data object
 */
export const buildRapidParams = (str: string) => {
  const entries = str.split(";");
  const map = new Map();
  for (let i = 0; i < entries.length - 1; i++) {
    const [key, val] = entries[i].split(":");
    if (
      key !== null &&
      key !== "null" &&
      key !== "" &&
      val !== null &&
      val !== "null" &&
      val !== ""
    ) {
      map.set(key, val);
    }
  }
  return Object.fromEntries(map);
};
